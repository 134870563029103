import React, { useEffect, useState, useRef } from "react";
import style from "./WeeklyWorkout.module.css";
import { useLocation } from "react-router-dom";
import { baseurl } from "../Url";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import WorkOut from "../../assests/ActiveWeightlifting-sport-exercise-gym (1).png";
import TimerImage from "../../assests/Chronometer.png";
import { Doughnut } from "react-chartjs-2";
import TrophyImage from "../../assests/Trophy.png";
import { FaCheck, FaChevronDown, FaChevronUp } from "react-icons/fa6";
import { IoMdClose } from "react-icons/io";
import SecondTrophyImage from "../../assests/SecondTrophy.png";
import ThirdTrophyImage from "../../assests/ThirdTrophy.png";
import FourthTrophyImage from "../../assests/FourthTrophy.png";
import ClockImage from "../../assests/alarm-clock.png";
import Loader from "../Loder/Loader";
import {
  Alert,
  Snackbar,

} from "@mui/material";
import { useOutletContext } from 'react-router-dom';

const WeeklyWorkout = () => {
  const { finishPopup, setFinishPopup,setTimeRunning,finished,setFinished,timeRunning,setAttended,attended } = useOutletContext();
  const location = useLocation();
  const state = location.state;

  const USERID = localStorage.getItem("USERID");
  const OrgID = localStorage.getItem("ORGID");

  const [assessment, setAssessment] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentAssessment, setCurrentAssessment] = useState({});
  const [validateClicked, setValidateClicked] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [result, setResult] = useState(false);
  const [assessmentData, setAssessmentData] = useState({});
  const [timeLeft, setTimeLeft] = useState(null);
  const [summaryDetail,setSummaryDetail]=useState({})
  const [expandedIndex, setExpandedIndex] = useState(null); 
  const [timeOuted,setTimeOuted]=useState(false);
  const [randomID, setRandomID] = useState("");
  const [allotedTime, setAllotedTime] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error,setError]=useState("")
  const inputRefs = useRef({});
  const highlightRefs = useRef({});

  const handleToggle = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };
  const timerRef = useRef();

  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  const handleSubmit = () => {
    if (allotedTime !== null) {
      const totalTimeInSeconds = allotedTime * 60; 
      const timeTaken = totalTimeInSeconds - timeLeft; 
  
      assessmentValidate(timeTaken); 
    }
  };
  

  const attendedCount = Object.values(assessmentData).filter(
    (data) => data.status === "attended"||data.status === "skipped"
  ).length;

  const totalCount = assessment.length;
  const progressPercentage = totalCount > 0 ? (attendedCount / totalCount) * 100 : 0;
  

  const getCurrentDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const currentDate = getCurrentDate();

  const numbers = Array.from(
    { length: assessment.length },
    (_, index) => index + 1
  );

  const handleOptionClick = (option, index) => {
    const uniqueId = currentAssessment.unique_id;
    const isMultiSelect = currentAssessment.isMultiSelect;

    setAssessmentData((prev) => {
        const prevAnswers = prev[uniqueId]?.answers || [];
        let newAnswers;

        if (isMultiSelect) {
            if (prevAnswers.includes(option)) {
                newAnswers = prevAnswers.filter((ans) => ans !== option);
            } else {
                newAnswers = [...prevAnswers, option];
            }
        } else {
            newAnswers = [option];
        }

        return {
            ...prev,
            [uniqueId]: {
                ...prev[uniqueId],
                answers: newAnswers,
            },
        };
    });

    setSelectedOptions((prev) => {
        if (isMultiSelect) {
            const selectedIndexes = prev[uniqueId] || [];
            return {
                ...prev,
                [uniqueId]: selectedIndexes.includes(index)
                    ? selectedIndexes.filter((i) => i !== index)
                    : [...selectedIndexes, index],
            };
        } else {
            return {
                ...prev,
                [uniqueId]: [index],  
            };
        }
    });
};

const handleInputChange = (index, value) => {
  const uniqueId = currentAssessment.unique_id;
  setAssessmentData((prev) => {
    const updatedAnswers = [...(prev[uniqueId]?.answers || [])];
    updatedAnswers[index] = value;
    return {
      ...prev,
      [uniqueId]: {
        ...prev[uniqueId],
        answers: updatedAnswers,
      },
    };
  });
};

const formatQuestionText = (text) => {
  return (
    <span
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
};

const renderQuestionWithInputs = (question) => {
  const parts = question.split(/\[blank (\d+)\]/);
  const uniqueId = currentAssessment.unique_id;
  const status = assessmentData[uniqueId]?.status;
  
  return (
    <div className={style.inputWrapper}> {/* Flexbox container */}
      {parts.map((part, index) => {
        if (index % 2 === 1) {
          const blankIndex = parseInt(parts[index], 10) - 1;
          let inputStyle = {};

          if (status === "correct") {
            inputStyle = { color: "#00DFC7" };
          } else if (status === "incorrect") {
            inputStyle = { color: "#FF2443" };
          } else {
            inputStyle = { color: "#FF7A00" };
          }

          // Calculate dynamic width based on input value length
          const inputValue = assessmentData[uniqueId]?.answers[blankIndex] || "";
          const inputWidth = `${inputValue.length * 10 + 20}px`;

          return (
            <React.Fragment key={index}>
              <input
                type="text"
                value={inputValue}
                onChange={(e) => handleInputChange(blankIndex, e.target.value)}
                className={style.blankInput}
                disabled={assessmentData[currentAssessment.unique_id]?.answered}
                style={{ ...inputStyle, width: inputWidth }}
              />
            </React.Fragment>
          );
        } else {
          return (
            <React.Fragment key={index}>
              {formatQuestionText(part)}
            </React.Fragment>
          );
        }
      })}
    </div>
  );
};

  const renderQuestionWithDropdowns = (question, options) => {
    const parts = question.split("[dropdown]");
    const uniqueId = currentAssessment.unique_id;
  
    return (
      <p>
        {parts.map((part, index) => {
          if (index < parts.length - 1) {
            // Render dropdowns where necessary
            return (
              <React.Fragment key={index}>
                {formatQuestionText(part)}
                <select
                  value={assessmentData[uniqueId]?.answers?.[index] || ""}
                  onChange={(e) => {
                    const newAnswers = [...(assessmentData[uniqueId]?.answers || [])];
                    newAnswers[index] = e.target.value;
                    setAssessmentData((prev) => ({
                      ...prev,
                      [uniqueId]: {
                        ...prev[uniqueId],
                        answers: newAnswers,
                      },
                    }));
                  }}
                  className={style.blankDropdown}
                  disabled={assessmentData[currentAssessment.unique_id]?.answered}
                >
                  <option disabled value="">
                    Select an option
                  </option>
                  {options.map((option, i) => (
                    <option key={i} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </React.Fragment>
            );
          }
  
          // Render the text after the last dropdown
          return (
            <React.Fragment key={index}>
              {formatQuestionText(part)}
            </React.Fragment>
          );
        })}
      </p>
    );
  };
  

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
  
    const sourceDroppableId = result.source.droppableId;
    const destinationDroppableId = result.destination.droppableId;
    const uniqueId = currentAssessment.unique_id;
  
    setAssessmentData((prev) => {
      const prevAnswers = prev[uniqueId]?.answers || { left: [], right: [] };
  
      let newAnswers = { ...prevAnswers };
  
      if (sourceDroppableId.startsWith("left") && destinationDroppableId.startsWith("left")) {
        const items = Array.from(newAnswers.left);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        newAnswers.left = items;
      } else if (sourceDroppableId.startsWith("right") && destinationDroppableId.startsWith("right")) {
        const items = Array.from(newAnswers.right);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        newAnswers.right = items;
      }
  
      return {
        ...prev,
        [uniqueId]: {
          ...prev[uniqueId],
          answers: newAnswers,
        },
      };
    });
  };
  
  
  const handleArrangeDragEnd = (result) => {
    if (!result.destination) return;

    const uniqueId = currentAssessment.unique_id;
    const items = Array.from(assessmentData[uniqueId]?.answers || []);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setAssessmentData((prev) => ({
      ...prev,
      [uniqueId]: {
        ...prev[uniqueId],
        answers: items,
      },
    }));
  };

  const handleCellValueChange = (rowIndex, colIndex, value, clueID, clueNumber) => {
    const uniqueId = currentAssessment.unique_id;

    setAssessmentData(prevData => {
        const newAnswers = prevData[uniqueId]?.answers.map((row, i) =>
            row.map((cell, j) =>
                i === rowIndex && j === colIndex
                    ? { ...cell, cluevalue: value.toUpperCase(), clueID, clueNumber }
                    : cell
            )
        ) || [];

        return {
            ...prevData,
            [uniqueId]: {
                ...prevData[uniqueId],
                answers: newAnswers,
            },
        };
    });

    // Log current state for debugging
    console.log("Current Cell:", rowIndex, colIndex);
    console.log("Refs:", inputRefs.current[uniqueId]);

    // Focus management
    const totalCells = (assessmentData[uniqueId]?.answers || []).flat().length;
    const currentCellIndex = rowIndex * (assessmentData[uniqueId]?.answers[0]?.length || 0) + colIndex;
    const nextCellIndex = currentCellIndex + 1;
    console.log("Next Cell Index:", nextCellIndex);

    if (nextCellIndex < totalCells) {
        const nextInputRef = inputRefs.current[uniqueId]?.[nextCellIndex];
        if (nextInputRef) {
            console.log("Focusing on:", nextInputRef);
            nextInputRef.focus();
        }
    }
};

const handleWordSelection = () => {
  const selection = window.getSelection();
  const selectedText = selection.toString().trim();
  const uniqueId = currentAssessment.unique_id;

  if (selectedText) {
    // Access or create a container ref for the current assessment
    if (!highlightRefs.current[uniqueId]) {
      highlightRefs.current[uniqueId] = document.createElement('div');
    }
    const container = highlightRefs.current[uniqueId];
    const range = selection.getRangeAt(0);

    if (assessmentData[uniqueId]?.lastSelectedText === selectedText) {
      const highlightedElement = container.querySelector('.highlighted');
      if (highlightedElement) {
        highlightedElement.replaceWith(highlightedElement.textContent);
      }

      // Reset the highlight and update assessment data
      setAssessmentData((prev) => ({
        ...prev,
        [uniqueId]: {
          ...prev[uniqueId],
          lastSelectedText: "",
          highlightContent: container.innerHTML,  
        },
      }));
    } else {
      const previousHighlight = container.querySelector('.highlighted');
      if (previousHighlight) {
        previousHighlight.replaceWith(previousHighlight.textContent);
      }

      const span = document.createElement('span');
      span.className = 'highlighted';
      span.style.color = "#FF7A00"; 
      span.textContent = selectedText;
      range.deleteContents();
      range.insertNode(span); 
      
      const newContent = container.innerHTML;
      setAssessmentData((prev) => ({
        ...prev,
        [uniqueId]: {
          ...prev[uniqueId],
          lastSelectedText: selectedText,
          highlightContent: newContent, 
        },
      }));
    }
    selection.removeAllRanges();
  }
};

  const handleNext = () => {
    setCurrentIndex(currentIndex + 1);
    setValidateClicked(false);
  };

  const handleSkip = () => {
    const uniqueId = currentAssessment?.unique_id;
    if (assessmentData[uniqueId]?.status === 'viewed') {
      setAssessmentData((prev) => ({
        ...prev,
        [uniqueId]: {
          ...prev[uniqueId],
          status: 'skipped',
        },
      }));
    }

    
    if (uniqueId && assessmentData[uniqueId]) {
        const currentAssmnt = assessment[currentIndex];
        let resetAnswers;
        let resetHighlightContent = "";
        let resetLastSelectedText = "";
        let resetAcrossClues = [];
        let resetDownClues = [];

        if (currentAssmnt.type === "Match the following" || currentAssmnt.type === "Match the following with image") {
          const filteredLeft = (currentAssmnt.left || []).filter(
            (leftData) => leftData.trim() !== ""
        );
        const filteredRight = (currentAssmnt.right || []).filter(
            (rightData) => rightData.trim() !== ""
        );
            resetAnswers = {
                left: filteredLeft,
                right: filteredRight,
            };
        }  else if (currentAssmnt.type === "Rearrange") {
            resetAnswers =currentAssmnt.options || [];
        } else if (currentAssmnt.type === 'cross word puzzle') {
            resetAnswers = currentAssmnt.options.map(row => (
                row.map(cell => ({
                    clueID: cell.clueID || "",
                    clueNumber: cell.clueNumber,
                    cluevalue: '',
                    disabled: cell.cluevalue === '',
                }))
            ));

            resetAcrossClues = currentAssmnt.left.map(clueObj => ({
                id: clueObj.id,
                number: clueObj.number,
                clue: clueObj.clue,
                answer: ''
            }));
            resetDownClues = currentAssmnt.right.map(clueObj => ({
                id: clueObj.id,
                number: clueObj.number,
                clue: clueObj.clue,
                answer: '' 
            }));
        } else if (currentAssmnt.type === "highlight") {
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = currentAssmnt.options;
            resetHighlightContent = tempDiv.textContent || tempDiv.innerText || ""; 
            resetLastSelectedText = "";
        } else {
            resetAnswers = [];
        }

        setAssessmentData((prev) => ({
            ...prev,
            [uniqueId]: {
                ...prev[uniqueId],
                answers: resetAnswers,
                highlightContent: resetHighlightContent,
                lastSelectedText: resetLastSelectedText,
                cluesAcross: resetAcrossClues,
                cluesDown: resetDownClues,
            },
        }));
        setSelectedOptions((prev) => {
          const { [uniqueId]: _, ...rest } = prev; 
          return rest;
      });
    }

    // Proceed to next question
    setCurrentIndex(currentIndex + 1);
    setValidateClicked(false);
};

  const handleEndTest = () => {
    if (!finished) {
      setFinishPopup(true); 
    }
  };


  async function assessmentGet() {
    await fetch(`${baseurl}/fetch/daily/weakly/exams`, {
      method: "POST",
      body: JSON.stringify({
        org_id: OrgID,
        user_id: USERID,
        date: currentDate,
        type: "weekly",
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          const modifiedData = data.data[0]?.questions.map((question, index) => {
            const baseQuestion = {
              ...question,
              question_no: index + 1,
            };
    
            if (question.type === "Match the following"||question.type === "Match the following with image") {
              return {
                ...baseQuestion,
                draggable: "right",
              };
            } else if (question.type === "multiple choices") {
              const isMultiSelect = question.answer.length > 1;
              return {
                ...baseQuestion,
                isMultiSelect,
              };
            }
            return baseQuestion;
          });
    
          setAssessment(modifiedData);
          setRandomID(data.data[0]?.random_id)
          setAllotedTime(data.data[0]?.alloted_time || 30); 
          setTimeout(() => {
            setLoading(false)
          }, 1000);
        } else {
          setAssessment([]);
          setCurrentAssessment({});
          setTimeout(() => {
            setLoading(false)
          }, 1000);
        }
      });
  }

  async function assessmentValidate(time_taken) {
    const questionId = currentAssessment.id
      ? currentAssessment.id.toString()
      : "";
    let answerToSend;
      if (currentAssessment.type === "Match the following"||currentAssessment.type === "Match the following with image") {
          if (currentAssessment.draggable === "right") {
              answerToSend = assessmentData[currentAssessment.unique_id]?.answers?.right || [];
          } else if (currentAssessment.draggable === "left") {
              answerToSend = assessmentData[currentAssessment.unique_id]?.answers?.left || [];
          }
      }else if(currentAssessment.type === "cross word puzzle"){
        answerToSend = assessmentData[currentAssessment.unique_id]?.answers.map(answerGroup => (
         Array.isArray(answerGroup) ? 
           answerGroup.map(answer => ({
               clueID: answer.clueID,
               clueNumber: answer.clueNumber,
               cluevalue: answer.cluevalue,
           })) :
           answerGroup 
     ));
     }else if(currentAssessment.type==="highlight"){
      answerToSend=[assessmentData[currentAssessment.unique_id]?.lastSelectedText]||[]
    }else if (currentAssessment.type === "fill in the blanks") {
      answerToSend = assessmentData[currentAssessment.unique_id]?.answers.map(answer => answer.trim()) || [];
    }
     else {
          answerToSend = assessmentData[currentAssessment.unique_id]?.answers || [];
      }
    await fetch(`${baseurl}/user/drill/assignment/answers/validation`, {
      method: "POST",
      body: JSON.stringify({
        random_id:randomID,
        organization_ID: OrgID,
        question: currentAssessment.question,
        question_id: questionId,
        user_id: USERID,
        answer: answerToSend,
        question_type: currentAssessment.type,
        options: currentAssessment.options ? currentAssessment.options : [],
        unique_id: currentAssessment.unique_id,
        left: currentAssessment.left ? currentAssessment.left : [],
        right: currentAssessment.right ? currentAssessment.right : [],
        actual_ans:currentAssessment.answer,
        question_explained:currentAssessment.question_explained,
        time_taken: time_taken.toString(),
        markif_correct:"",
        markif_incorrect: "",
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if(data.status===true){
          if(!attended){
            setAttended(true)
          }
          setAssessmentData((prev) => ({
            ...prev,
            [currentAssessment.unique_id]: {
              ...prev[currentAssessment.unique_id],
              status: "attended",
              answered:true,
            },
          }));
        }
        
      });
  }

  async function assessmentSummary() {
    await fetch(`${baseurl}/drill/summary`, {
      method: "POST",
      body: JSON.stringify({
        random_id: randomID,
        user_id: USERID,
       
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if(data.status===true){
          const transformedData = {
            correctAns: data.Correct_ans?.[0]?.Correct_ans || 0,
            incorrectAns: data.Incorrect_ans?.[0]?.Incorrect_ans || 0,
            skippedQuestions: data.Skipped_questions?.[0]?.Skipped_questions || 0,
            totalQuestions: data.Total_questions?.[0]?.Total_questions || 0,
            data: data.data.map((item) => ({
              ...item,
              isExpand: false,
            })),
            percentage: data.percentage?.[0]?.percentage || 0,
          };
         setResult(true)
         setSummaryDetail(transformedData)
         setFinishPopup(false)
        }
      });
  }

  useEffect(() => {
    assessmentGet();
  }, [location.state]);

  useEffect(() => {
    if (assessment.length > 0) {
      const currentAssmnt = assessment[currentIndex];
      const uniqueId = currentAssmnt?.unique_id;
  
      setCurrentAssessment(currentAssmnt);
  
      if (!assessmentData[uniqueId]) {
        let initialAnswers;
        let initialAcrossClues = [];
        let initialDownClues = [];
        let highlightContent = ""
        let lastSelectedText = ""
  
        if (currentAssmnt.type === "Match the following" ||currentAssmnt.type === "Match the following with image") {
          const filteredLeft = (currentAssmnt.left || []).filter(
            (leftData) => leftData.trim() !== ""
          );
          const filteredRight = (currentAssmnt.right || []).filter(
            (rightData) => rightData.trim() !== ""
          );
          initialAnswers = {
            left: filteredLeft,
            right: filteredRight,
          };
        }else if (currentAssmnt.type === "Rearrange") {
          initialAnswers = currentAssmnt.options || [];
        }else if (currentAssmnt.type === 'cross word puzzle') {
          const grid = currentAssmnt.options.map(row => (
              row.map(cell => ({
                  clueID: cell.clueID || "",
                  clueNumber: cell.clueNumber,
                  cluevalue: '',
                  disabled: cell.cluevalue === '',
              }))
          ));
          initialAnswers = grid;

          initialAcrossClues = currentAssmnt.left.map(clueObj => ({
              id: clueObj.id,
              number: clueObj.number,
              clue: clueObj.clue,
              answer: ''
          }));
          initialDownClues = currentAssmnt.right.map(clueObj => ({
              id: clueObj.id,
              number: clueObj.number,
              clue: clueObj.clue,
              answer: ''
          }));
      }else if(currentAssmnt.type === "highlight"){
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = currentAssmnt.options;
        highlightContent = tempDiv.textContent || tempDiv.innerText || ""; 
        lastSelectedText = "";
      } else {
          initialAnswers = [];
        }
  
        setAssessmentData((prev) => ({
          ...prev,
          [uniqueId]: {
              answers: initialAnswers,
              status: "",
              explanations: "",
              cluesAcross: initialAcrossClues, 
              cluesDown: initialDownClues, 
              highlightContent:highlightContent,
              lastSelectedText:lastSelectedText
          },
        }));
      } else if (!assessmentData[uniqueId]?.status) {
        setAssessmentData((prev) => ({
          ...prev,
          [uniqueId]: {
            ...prev[uniqueId],
            status: 'viewed',
          },
        }));
      }
    }
  }, [currentIndex, assessment, assessmentData]);

  useEffect(() => {
    if (allotedTime !== null && !finished) {  
      setTimeLeft(allotedTime * 60);  
      setTimeRunning(true) 
      timerRef.current = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime > 0) {
            return prevTime - 1;
          } else {
            clearInterval(timerRef.current);
            setTimeRunning(false)
            setTimeOuted(true);
            if (!finished) {
              assessmentSummary();
            }
            return 0;
          }
        });
      }, 1000);
  
      return () => clearInterval(timerRef.current);
    }
  }, [allotedTime, finished]);

  useEffect(() => {
    if (currentAssessment) {
        if (!inputRefs.current[currentAssessment.unique_id]) {
            inputRefs.current[currentAssessment.unique_id] = [];
        }
    }
  }, [currentAssessment]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (timeRunning && !finished) {
        event.preventDefault();
        event.returnValue = ''; 
      }
    };
  
    window.addEventListener('beforeunload', handleBeforeUnload);
  
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [timeRunning, finished]);

  useEffect(() => {
    if (currentAssessment) {
        if (!inputRefs.current[currentAssessment.unique_id]) {
            inputRefs.current[currentAssessment.unique_id] = [];
        }
    }
  }, [currentAssessment]);
  return (
    <div className={style.container}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={error}
        sx={{ width: "25rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setError(false);
        }}
      >
        <Alert
          severity="error"
          variant="filled"
          sx={{ width: "100%", fontSize: "1rem" }}
        >
          Please enter answer..
        </Alert>
      </Snackbar>
      <div className={style.assessmentSection}>
        <div className={style.header}>
          <div className={style.headerText}>
            <span>Weekly Workout</span>
          </div>
          <div className={style.headerIcon}>
            <img src={WorkOut} alt="calendar" />
          </div>
        </div>
        {loading?
        <Loader/>
        :
        <>
        {currentAssessment.question_no &&!result?
        <div className={style.progressBarSection}>
          <div
            className={style.progressBar}
            style={{
              width: "95%",
              backgroundColor: "#8E88903D",
              borderRadius: "2rem",
            }}
          >
            <div
              style={{
                width: `${progressPercentage}%`,
                backgroundColor: "#595BDB",
                height: "12px",
                transition: "width 0.5s ease-in-out",
                borderRadius: "1rem",
              }}
            ></div>
          </div>
          <div className={style.progressBarCount}>
          <p>{`${attendedCount}/${totalCount}`}</p>
          </div>
        </div>
          :""}
        {currentAssessment.question_no ? (
          <div className={style.body}>
            <div className={style.bodyLeft}>
            {result ? (
                <>
                  <div className={style.resultSection}>
                        {timeOuted ? (
                          <>
                            <div className={style.resultSectionHeder}>
                              <h4 style={{color:"#FF2443"}}>Time's Up!</h4>
                              <p>You ran out of time. Don't worry, you can try again tomorrow. Practice makes perfect!</p>
                            </div>
                            <div className={style.resultSectionImage}>
                              <img src={ClockImage} alt="Time Out" /> 
                            </div>
                            <div className={style.resultSectionBottomContent}>
                              <p>You’ve scored</p>
                              <h4>{summaryDetail.correctAns}</h4>
                            </div>
                          </>
                        ) : (
                          summaryDetail?.percentage!== undefined && (
                            <>
                              {summaryDetail.percentage >= 85 ? (
                                <>
                                  <div className={style.resultSectionHeder}>
                                    <h4 style={{color:"#D09B00"}}>Congratulations! </h4>
                                    <p>You’ve done an excellent job in the test. Keep up the great work.</p>
                                  </div>
                                  <div className={style.resultSectionImage}>
                                    <img src={TrophyImage} alt="Winner" />
                                  </div>
                                </>
                              ) : summaryDetail.percentage >= 60 ? (
                                <>
                                  <div className={style.resultSectionHeder}>
                                    <h4 style={{color:"#00CBF4"}}>Well Done! </h4>
                                    <p>You’ve performed well in the test. Keep up the good work.</p>
                                  </div>
                                  <div className={style.resultSectionImage}>
                                    <img src={SecondTrophyImage} alt="Winner" />
                                  </div>
                                </>
                              ) : summaryDetail.percentage >= 40 ? (
                                <>
                                  <div className={style.resultSectionHeder}>
                                    <h4 style={{color:"#FF8800"}}>Nice effort!</h4>
                                    <p>There’s room for improvement, but you’re on the right track. Keep practicing.</p>
                                  </div>
                                  <div className={style.resultSectionImage}>
                                    <img src={ThirdTrophyImage} alt="Average" />
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className={style.resultSectionHeder}>
                                    <h4 style={{color:"#FF2443"}}>Don't give up!</h4>
                                    <p>With consistent practice, you can achieve your goals.</p>
                                  </div>
                                  <div className={style.resultSectionImage}>
                                    <img src={FourthTrophyImage} alt="Try Again" />
                                  </div>
                                </>
                              )}
                              <div className={style.resultSectionBottomContent}>
                                <p>You’ve scored</p>
                                <h4>{summaryDetail.correctAns}</h4>
                              </div>
                            </>
                          )
                        )}
                      </div>
                  <div className={style.finalAnswerSection}>
                    <div className={style.finalAnswerSectionHeader}>
                      <p>Answers:</p>
                    </div>
                    <div className={style.finalAnswerSectionBody}>
                    <div className={style.finalAnswerSectionBody}>
                    {summaryDetail.data?.map((item, index) => (
                      <div key={index} className={`${style.finalAnswerBodyData} ${
                        expandedIndex === index ? style.expanded : ""
                      }`}>
                        <div className={style.answerBodyHeader}>
                          <div className={style.answerBodyHeaderLeft}>
                            {item.answer_is==="Correct" ? (
                              <FaCheck style={{ color: "#00DFC7" }} />
                            ) : (
                              <IoMdClose style={{ color: "#FF7C8E" }} />
                            )}
                            <span>{index + 1}.</span>
                            {item.answer && item.answer.length > 0 ? (
                                  <div>
                                    {item.answer.map((answer, answerIndex) => (
                                      <p key={answerIndex}>{answer}</p>
                                    ))}
                                  </div>
                                ) : (
                                  <p>No answers available</p>
                                )}
                          </div>
                          <div className={style.answerBodyHeaderRight} onClick={() => handleToggle(index)}>
                          {expandedIndex === index ? <FaChevronUp /> : <FaChevronDown />}
                          </div>
                        </div>
                        {expandedIndex === index &&  (
                          <div className={style.answerBodyData}>
                            <div className={style.answerBodyQuestion}>
                              <h4>Question</h4>
                              {item.question_type === "fill in the blanks" ? (
                                  <div>
                                    {item.question.split(/\[blank \d+\]/).map((part, partIndex) => (
                                      <React.Fragment key={partIndex}>
                                        <p dangerouslySetInnerHTML={{ __html: part }}></p>
                                        {partIndex < item.answer.length && (
                                          <input
                                            type="text"
                                            readOnly
                                            style={{ border:"none",borderBottom:"1px solid" }}
                                          />
                                        )}
                                      </React.Fragment>
                                    ))}
                                  </div>
                                ) : (
                                  <p dangerouslySetInnerHTML={{ __html: item.question }}></p>
                                )}
        
                              {item.question_type === "multiple choices" && item.options && item.options.length > 0 && (
                                    <div className={style.answerBodyOptions}>
                                      
                                      <ul>
                                        {item.options.map((option, optionIndex) => (
                                          <li key={optionIndex}>{option}</li>
                                        ))}
                                      </ul>
                                    </div>
                                  )}
                                  {item.question_type === "Match the following" && item.left && item.left.length > 0 && (
                                    <div className={style.answerBodyMatch}>
                                      {item.left.map((leftItem, index) => (
                                        <div key={index} className={style.matchRow}>
                                          <div className={style.matchRowCardLeft}>
                                            <p>{leftItem}</p>
                                          </div>
                                          <div className={style.matchRowCardRight}>
                                            <p>{item.right && item.right[index]}</p>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                   {item.question_type === "Match the following with image" && item.left && item.left.length > 0 && (
                                      <div className={style.answerBodyMatch}>
                                        {item.left.map((leftItem, index) => (
                                          <div key={index} className={style.matchRow}>
                                            <div className={style.matchRowImageCard}>
                                            <img src={leftItem} alt={`Option ${index + 1}`} width="200px" />
                                            </div>
                                            <div className={style.matchRowCardRight}>
                                              <p>{item.right && item.right[index]}</p>
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    )}
                                  </div>
                                  {item.answer_is!=="Correct"?
                            <div className={style.answerBodyCorrectAnswer}>
                              <h4>Correct answer</h4>
                              {item.question_type === "Match the following" && item.left && item.left.length > 0 ? (
                                  <div className={style.answerBodyMatch}>
                                    {item.left.map((leftItem, index) => (
                                      <div key={index} className={style.matchRow}>
                                        <div className={style.matchRowCardLeft}>
                                          <p>{leftItem}</p>
                                        </div>
                                        <div className={style.matchRowCardRight}>
                                          <p>{item.actual_ans && item.actual_ans[index]}</p>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                ): item.question_type === "fill in the blanks" ? (
                                  <div>
                                    {item.actual_ans && item.actual_ans.length > 0 ? (
                                      item.actual_ans.map((answerObj, answerIndex) => (
                                        <p key={answerIndex}>
                                         
                                          {Object.values(answerObj)[0]}
                                        </p>
                                      ))
                                    ) : (
                                      <p>No correct answers available</p>
                                    )}
                                  </div>
                                ) :item.question_type === "Match the following with image" && item.left && item.left.length > 0 ?(
                                  <div className={style.answerBodyMatch}>
                                    {item.left.map((leftItem, index) => (
                                      <div key={index} className={style.matchRow}>
                                        <div className={style.matchRowImageCard}>
                                        <img src={leftItem} alt={`Option ${index + 1}`} width="200px" />
                                        </div>
                                        <div className={style.matchRowCardRight}>
                                          <p>{item.right && item.right[index]}</p>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                ) : (
                                  item.actual_ans && item.actual_ans.length > 0 ? (
                                    <div>
                                      {item.actual_ans.map((answer, answerIndex) => (
                                        <p key={answerIndex}>{answer}</p>
                                      ))}
                                    </div>
                                  ) : (
                                    <p>No answers available</p>
                                  )
                                )}
                               
                                <p
                              dangerouslySetInnerHTML={{
                                __html: item.question_explained,
                              }}
                            ></p>
                            </div>
                            :''}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className={style.questionSection}>
                  <div className={style.question}>
                    <span>{currentAssessment.question_no}.</span>
                    {currentAssessment.type === "fill in the blanks" ? (
                      <p>
                        {renderQuestionWithInputs(
                          currentAssessment.question,
                        )}
                      </p>
                    ) : currentAssessment.type === "select the answers" ? (
                      <p>
                        {renderQuestionWithDropdowns(
                          currentAssessment.question,
                          currentAssessment.options
                        )}
                      </p>
                    ): (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: currentAssessment.question,
                        }}
                      ></p>
                    )}
                  </div>
                  <div className={style.questionBody}>
                    {currentAssessment.type === "ordinary" ? (
                      <div className={style.ordinaryContainer}>
                        <div className={style.ordinarySection}>
                          <div className={style.ordinarySectionRight}>
                          <input
                            disabled={assessmentData[currentAssessment.unique_id]?.answered}
                            onChange={(e) => {
                              const uniqueId = currentAssessment.unique_id;
                              setAssessmentData((prev) => ({
                                ...prev,
                                [uniqueId]: {
                                  ...prev[uniqueId],
                                  answers: [e.target.value],
                                },
                              }));
                            }}
                            value={assessmentData[currentAssessment.unique_id]?.answers[0] || ""}
                          />
                          </div>
                        </div>
                      </div>
                    ) : currentAssessment.type === "multiple choices" ? (
                      <div className={style.multipleChoiceContainer}>
                      {currentAssessment.options?.filter(option => option.trim() !== "").map((option, index) => {
                         const selectedIndexes = selectedOptions[currentAssessment.unique_id] || [];
                         const isSelected = selectedIndexes.includes(index);
             
                         let optionClass = "";
                         if (isSelected) {
                             optionClass =
                                 style.selected;
                         } else {
                             optionClass = style.unselected;
                         }
             
                        return (
                          <div
                            key={index}
                            onClick={() =>{
                              if(assessmentData[currentAssessment.unique_id]?.answered!==true){
                                handleOptionClick(option, index)}}
                               }
                            className={`${style.multipleChoiceAnswer} ${optionClass}`}
                          >
                            <p>{option}</p>
                          </div>
                        );
                      })}
                    </div>
                    ) : currentAssessment.type === "Match the following" ? (
                      <div className={style.matchFollowingContainer}>
                      <DragDropContext onDragEnd={handleOnDragEnd}>
                        {(assessmentData[currentAssessment.unique_id]?.answers?.left || []).filter(leftData => leftData.trim() !== "").map((leftData, i) => {
                           return (
                          <React.Fragment key={i}>
                            <Droppable droppableId={`left-${i}`}>
                              {(provided) => (
                                <div
                                  className={style.matchFollowingLeft}
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                >
                                  <Draggable
                                    draggableId={`left-${i}`}
                                    index={i}
                                    isDragDisabled={
                                      currentAssessment.draggable === "right" ||
                                      assessmentData[currentAssessment.unique_id]?.answered
                                    }
                                  >
                                    {(provided) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        className={style.matchFollowingCard}
                                      >
                                        <p>{leftData}</p>
                                      </div>
                                    )}
                                  </Draggable>
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                  
                            <Droppable droppableId={`right-${i}`}>
                              {(provided) => (
                                <div
                                  className={style.matchFollowingRight}
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                >
                                  <Draggable
                                    draggableId={`right-${i}`}
                                    index={i}
                                    isDragDisabled={
                                      currentAssessment.draggable === "left" ||
                                      assessmentData[currentAssessment.unique_id]?.answered
                                    }
                                  >
                                    {(provided) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        className={style.matchFollowingCard}
                                      >
                                        <p>{(assessmentData[currentAssessment.unique_id]?.answers?.right || [])[i]}</p>
                                      </div>
                                    )}
                                  </Draggable>
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </React.Fragment>
                        )})}
                      </DragDropContext>
                    </div>
                    ) : currentAssessment.type === "Match the following with image" ? (
                      <div className={style.matchFollowingContainer}>
                      <DragDropContext onDragEnd={handleOnDragEnd}>
                        {(assessmentData[currentAssessment.unique_id]?.answers?.left || []).filter(leftData => leftData.trim() !== "").map((leftData, i) => {
                           const matchValue = assessmentData[currentAssessment.unique_id]?.match?.[i];
                           let cardClassName;
                           if (matchValue === "1") {
                             cardClassName = style.correctCard;
                           } else if (matchValue === "0") {
                             cardClassName = style.errorCard;
                           } else {
                             cardClassName = style.defaultCard;
                           }
                           return (
                          <React.Fragment key={i}>
                            <Droppable droppableId={`left-${i}`}>
                              {(provided) => (
                                <div
                                  className={style.matchFollowingLeft}
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                >
                                  <Draggable
                                    draggableId={`left-${i}`}
                                    index={i}
                                    isDragDisabled={
                                      currentAssessment.draggable === "right" ||
                                      assessmentData[currentAssessment.unique_id]?.answered
                                    }
                                  >
                                    {(provided) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        className={`${style.matchFollowingImageCard} ${cardClassName}`}
                                      >
                                        <img src={leftData} alt={`Option ${i + 1}`}  />
                                      </div>
                                    )}
                                  </Draggable>
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                  
                            <Droppable droppableId={`right-${i}`}>
                              {(provided) => (
                                <div
                                  className={style.matchFollowingRight}
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                >
                                  <Draggable
                                    draggableId={`right-${i}`}
                                    index={i}
                                    isDragDisabled={
                                      currentAssessment.draggable === "left" ||
                                      assessmentData[currentAssessment.unique_id]?.answered
                                    }
                                  >
                                    {(provided) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        className={`${style.matchFollowingCard} ${cardClassName}`}
                                      >
                                        <p>{(assessmentData[currentAssessment.unique_id]?.answers?.right || [])[i]}</p>
                                      </div>
                                    )}
                                  </Draggable>
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </React.Fragment>
                        )})}
                      </DragDropContext>
                    </div>
                    ) : currentAssessment.type === "Rearrange" ? (
                      <DragDropContext onDragEnd={handleArrangeDragEnd}>
                      <Droppable droppableId="rearrange" direction="horizontal">
                        {(provided) => (
                          <div
                            className={style.reArrangeContainer}
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {assessmentData[currentAssessment.unique_id]?.answers.map((option, index) => (
                              <Draggable
                                key={index}
                                draggableId={`option-${index}`}
                                index={index}
                                isDragDisabled={assessmentData[currentAssessment.unique_id]?.answered}
                              >
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className={style.reArrangeCard}
                                  >
                                    <p>{option}</p>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                    ) : currentAssessment.type === "cross word puzzle"? (
                      <div className={style.crossWordContainer}>
                      <div className={style.crossWordPuzzle}>
                      {assessmentData[currentAssessment.unique_id]?.answers.map((row, rowIndex) => (
                        <div key={rowIndex} className={style.row}>
                          {row.map((cell, colIndex) => (
                            <div key={`${rowIndex}-${colIndex}`} className={style.cellContainer}>
                              {!cell.disabled && (
                                <input
                                  type="text"
                                  maxLength="1"
                                  className={ style.activeInput}
                                  value={cell.value}
                                  onChange={(e) => handleCellValueChange(rowIndex, colIndex, e.target.value, cell.clueID, cell.clueNumber)}
                                  disabled={cell.disabled}
                                  ref={el => {
                                    if (el) {
                                        if (!inputRefs.current[currentAssessment.unique_id]) {
                                            inputRefs.current[currentAssessment.unique_id] = [];
                                        }
                                        inputRefs.current[currentAssessment.unique_id][rowIndex * (assessmentData[currentAssessment.unique_id]?.answers[0]?.length || 0) + colIndex] = el;
                                    }
                                }}
                                />
                              )}
                              {cell.clueNumber && (  
                                <div className={style.clueNumber}>{cell.clueNumber}</div>
                              )}
                            </div>
                          ))}
                        </div>
                        ))}
                    </div>
                    <div className={style.PuzzleQuestion}>
                      <div className={style.QuestionAcross}>
                      <h4>Across</h4>
                      {assessmentData[currentAssessment.unique_id]?.cluesAcross.map((clueObj, index) => (
                        <div key={index} className={style.QuestionAcrossInputContainer}>
                          <span>{clueObj.number}.{clueObj.clue} </span>
                          
                        </div>
                      ))}
                      
                      </div>
                      <div className={style.QuestionDown}>
                      <h4>Down</h4>
                      {assessmentData[currentAssessment.unique_id]?.cluesDown.map((clueObj, index) => (
                          <div key={index} className={style.QuestionDownInputContainer}>
                            <span>{clueObj.number}.{clueObj.clue} </span>
                            
                          </div>
                        ))}
                        
                        </div>
                    </div>
                      </div>
                    ):currentAssessment.type === "Rewrite" ? (
                      <div className={style.ordinaryContainer}>
                        <div className={style.ordinarySection}>
                          <div className={style.ordinarySectionRight}>
                          <textarea
                          rows={6}
                              disabled={assessmentData[currentAssessment.unique_id]?.answered}
                              style={{
                                backgroundColor: assessmentData[currentAssessment.unique_id]?.status === "correct"
                                  ? "#00DFC7"
                                  : assessmentData[currentAssessment.unique_id]?.status === "incorrect"
                                  ? "#FF7C8E"
                                  : "transparent"
                              }}
                              onChange={(e) => {
                                const uniqueId = currentAssessment.unique_id;
                                setAssessmentData((prev) => ({
                                  ...prev,
                                  [uniqueId]: {
                                    ...prev[uniqueId],
                                    answers: [e.target.value],
                                  },
                                }));
                              }}
                              value={assessmentData[currentAssessment.unique_id]?.answers[0] || ""}
                            />
                          </div>
                        </div>
                      </div>
                    ):currentAssessment.type === "highlight" ? (
                      <div
                      ref={(el) => (highlightRefs.current[currentAssessment.unique_id] = el)}
                        dangerouslySetInnerHTML={{ __html: assessmentData[currentAssessment.unique_id]?.highlightContent || "" }}
                        onMouseUp={handleWordSelection}
                        style={{
                          height: "100px", 
                          overflowY: "auto",  
                          outline: "none", 
                          width: "100%"
                        }}
                      />
                    ): (
                      ""
                    )}
                  </div>
                  <div className={style.questionButtonSection}>
                  <div className={style.questionButtonLeft}>
                      {currentAssessment.type === "Match the following" ? (
                        <span>*Drag and place the answer.</span>
                      ) : (
                        ""
                      )}
                      {currentIndex!==assessment.length-1?
                      <p
                        onClick={() => {
                          if (currentIndex !== assessment.length - 1) {
                           
                            handleSkip();
                          }
                        }}
                      >
                        Skip
                      </p>
                      :null}
                    </div>
                    <div className={style.questionButtonRight}>
                      {assessmentData[currentAssessment.unique_id]?.answered ? (
                       <>
                         {currentIndex!==assessment.length-1?
                          <button
                            onClick={() => handleNext()}
                            disabled={currentIndex === assessment.length - 1}
                            style={{ background: "#00CBF480" }}
                          >
                            Next
                          </button>
                          :null}
                       </>
                      ) : (
                        <button
                          onClick={() => {
                          const uniqueId = currentAssessment.unique_id;
                          const answers = assessmentData[uniqueId]?.answers || [];

                          if (
                            currentAssessment.type === "Match the following" ||
                            currentAssessment.type === "Match the following with image"
                          ) {
                            const rightFromAssessment = currentAssessment.right.filter(right => right.trim() !== "") || [];
                            const rightFromData = answers?.right || [];

                            const areRightAnswersSame =
                              rightFromAssessment.length === rightFromData.length &&
                              rightFromAssessment.every((value, index) => value === rightFromData[index]);

                            if (areRightAnswersSame) {
                              setError(true);
                              return;
                            } else {
                              handleSubmit();
                              setValidateClicked(true);
                            }
                          }else if (currentAssessment.type === "Rearrange") {
                            const options = currentAssessment.options.filter(op => op.trim() !== "") || [];
                            const areAnswersSame =
                            options.length === answers.length &&
                            options.every((value, index) => value === answers[index]);
                            if (areAnswersSame) {
                              setError(true);
                              return;
                            } else {
                              handleSubmit();
                              setValidateClicked(true);
                            }
                            
                          }
                          else if(currentAssessment.type === "highlight"){
                            if(assessmentData[uniqueId]?.lastSelectedText===""){
                              setError(true);                            
                            }
                            else{
                              handleSubmit();
                             setValidateClicked(true);
                            }
                          }
                          else if (answers.length > 0) {
                            handleSubmit();
                            setValidateClicked(true);
                          } 
                          else {
                            setError(true);
                          }
                        }}
                      >
                        Submit
                      </button>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className={style.bodyRight}>
              {result ? (
                <div className={style.summeryContainer}>
                  <div className={style.summeryTopContent}>
                    <div className={style.roundGraph}>
                      <Doughnut
                        data={{
                          labels: ["Completed", "Remaining"],
                          datasets: [
                            {
                              data: [
                                summaryDetail?.percentage || 0, 
                                100 - (summaryDetail?.percentage || 0), 
                              ],
            
                              backgroundColor: ["#03CD09", "#3E3D4212"],
                              borderWidth: 0,
                            },
                          ],
                        }}
                        options={{
                          cutout: "85%",
                          plugins: {
                            legend: {
                              display: false,
                            },
                          },
                        }}
                      />
                      <div
                        className={style.graphText}
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          pointerEvents: "none",
                        }}
                      >
                        {Math.round(summaryDetail?.percentage || 0)}%
                      </div>
                    </div>
                    <div className={style.summeryTopText}>
                      <p>% of marks acquired</p>
                    </div>
                  </div>
                  <div className={style.summeryBottomContent}>
                    <div className={style.summeryBottomData}>
                      <div className={style.summeryBottomDataLeft}>
                        <span style={{ background: "#00DFC7" }}>{summaryDetail?.correctAns || 0}</span>
                      </div>
                      <div className={style.summeryBottomDataRight}>
                        <p>Correct Answers</p>
                      </div>
                    </div>
                    <div className={style.summeryBottomData}>
                      <div className={style.summeryBottomDataLeft}>
                        <span style={{ background: "#FF2443" }}>{summaryDetail?.incorrectAns || 0}</span>
                      </div>
                      <div className={style.summeryBottomDataRight}>
                        <p>Wrong Answers</p>
                      </div>
                    </div>
                    <div className={style.summeryBottomData}>
                      <div className={style.summeryBottomDataLeft}>
                        <span style={{ background: "#FFBE00" }}>{summaryDetail?.skippedQuestions || 0}</span>
                      </div>
                      <div className={style.summeryBottomDataRight}>
                        <p>Skipped Answers</p>
                      </div>
                    </div>
                    <div className={style.summeryBottomData}>
                      <div className={style.summeryBottomDataLeft}>
                        <span style={{ background: "#595BDB" }}>{summaryDetail?.totalQuestions || 0}</span>
                      </div>
                      <div className={style.summeryBottomDataRight}>
                        <p>Total Questions</p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className={style.timeContainer}>
                    <div className={style.timerIcon}>
                      <img src={TimerImage} alt="Timer" />
                    </div>
                    <div className={style.timerText}>
                    <p style={{ color: timeLeft <= 120 ? '#FF2443' : 'inherit' }} >{`${minutes}:${seconds < 10 ? `0${seconds}` : seconds} mins`}</p>
                    </div>
                  </div>
                  <div className={style.markContainer}>
                    <div className={style.markData}>
                      <div className={style.markDataLeft}>
                        <p>Attended</p>
                      </div>
                      <div
                        style={{ marginBottom: "8px" }}
                        className={style.markDataRight}
                      >
                        <p>
                          <span>{Object.values(assessmentData).filter(
                        (data) => data.status === "attended"
                      ).length}</span>/{totalCount}
                        </p>
                      </div>
                    </div>
                    <div className={style.markData}>
                      <div className={style.markDataLeft}>
                        <p>Unattended</p>
                      </div>
                      <div className={style.markDataRight}>
                      <p>{Object.values(assessmentData).filter(
                        (data) => data.status=== "skipped"
                      ).length}</p>
                      </div>
                    </div>
                  </div>
                  <div className={style.FinishButton}>
                    <button onClick={()=>{ 
                        handleEndTest()
                    }}>End Test</button>
                  </div>
                  <div className={style.questionPaletteHeader}>
                    <p>Question Palette:</p>
                  </div>
                  <div className={style.questionPalette}>
                    {numbers.map((number, index) => {
                      const questionId = assessment[index]?.unique_id;
                      const status =  assessmentData[questionId]?.status;

                      let backgroundColor;
                      if (currentIndex === index) {
                        backgroundColor = "#D9D9D9";
                      } else if (status === "skipped") {
                        backgroundColor = "#818085";
                      }else if (status === "attended") {
                        backgroundColor = "#BEBFFF";
                      } else {
                        backgroundColor = "#FFFFFF";
                      }

                      const numberStyle = {
                        backgroundColor,
                      };

                      return (
                        <div
                        onClick={() => {
                          const uniqueId = currentAssessment?.unique_id;
                          if (assessmentData[uniqueId]?.status === 'viewed') {
                            setAssessmentData((prev) => ({
                              ...prev,
                              [uniqueId]: {
                                ...prev[uniqueId],
                                status: 'skipped',
                              },
                            }));
                          }
                          setCurrentIndex(number - 1);
                          setValidateClicked(false);
                        }}
                          key={number}
                          className={style.number}
                          style={numberStyle}
                        >
                          <p> {number}</p>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
          </div>
        ) : (
          <div className={style.info}><p>No Weekly Workout found</p></div>
        )}
        </>}
      </div>
      
      {finishPopup?
      <>
      <div className={style.popupOverlay}></div>
        <div className={style.Popup}>
          <div className={style.popupText}>
            <p>Are you sure you want to end the test?</p>
          </div>
          <div className={style.PopupButton}>
          <button
            className={style.noButton}
              onClick={() => {
                setFinishPopup(false);
              }}
            >
              No
            </button>
            <button
            className={style.yesButton}
              onClick={(e) => {
                e.preventDefault();
                clearInterval(timerRef.current); 
                assessmentSummary()
                setFinished(true)
                setTimeRunning(false)
              }}
            >
              Yes
            </button>
           
          </div>
        </div>
      </>:""}
    </div>
  );
};

export default WeeklyWorkout;
